<template>
  <v-card>
    <div v-if="app_user.globalLayers.length > 0">
      {{ $t('NavigationSideBar.global') }}
      <v-divider
        class="my-3"
        color="white"
      />
      <v-checkbox
        v-for="globalLayer in app_user.globalLayers"
        :key="globalLayer.global_layer_id"
        v-model="global_layers.selectedGlobalLayer"
        class="pb-0"
        inset
        dense
        color="green"
        dark
        multiple
        :value="globalLayer"
        @click="setGlobalLayer(globalLayer)"
      >
        <template v-slot:label>
          <span style="font-size:12px">{{ $t(`GlobalLayer.${globalLayer.global_layer_id}.title`) }}</span>
        </template>
      </v-checkbox>
    </div>
    <div
      v-if="global_layers.customerLayers.length > 0"
      class="mt-2"
    >
      {{ $t('NavigationSideBar.layer') }}
      <v-divider
        class="my-3"
        color="white"
      />
      <v-checkbox
        v-for="customerLayer in global_layers.customerLayers"
        :key="customerLayer.global_layer_id"
        v-model="global_layers.selectedGlobalLayer"
        class="pb-0"
        inset
        dense
        color="green"
        dark
        multiple
        :value="customerLayer"
        @click="setGlobalLayer(customerLayer)"
      >
        <template v-slot:label>
          <span style="font-size:12px">{{ $t(`CustomerLayer.${customerLayer.global_layer_id}.title`) }}</span>
        </template>
      </v-checkbox>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  components: {
  },
  props: {
  },
  data () {
    return {};
  },
  computed: {
    ...mapState(['app_user', 'global_layers'])
  },
  watch: {
    $route() {}
  },
  mounted() {
    this.defaultServiceWS();
  },
  methods: {
    ...mapActions(['setGlobalLayer']),
    defaultServiceWS() {
      if (this.app_user.defaultService.GLOBAL?.service_type === 'WS' && this.global_layers.selectedGlobalLayer.length > 0) {
        this.app_user.defaultService.GLOBAL.global_layer_id.forEach(item => {
          const gLayer = this.app_user.globalLayers.find(layer => layer.global_layer_id === item.id);
          this.global_layers.selectedGlobalLayer.push(gLayer);
        });

        this.app_user.defaultService.GLOBAL.customer_layer_id.forEach(item => {
          const cLayer = this.global_layers.customerLayers.find(layer => layer.global_layer_id === item.id);
          this.global_layers.selectedGlobalLayer.push(cLayer);
        });
      }
    }
  }
};
</script>

